@use "sass:math";

.countries {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  min-width: 280px;
  max-width: calc(#{$CANVAS_WIDTH} / #{$CANVAS_HEIGHT} * 100vh);
  height: 100%;
  margin: 0 auto;
  background-position: 50% 75%;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: linear-gradient(216deg, #634D94 40%, #27225F 100%);
  z-index: 0;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: height(1237, $CANVAS_HEIGHT);
    background-image: url("./images/snow-top.png");
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: cover;
    z-index: -1;
  }

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: height(543, $CANVAS_HEIGHT);
    background-image: url("./images/snow-bot.png");
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: cover;
    z-index: -1;
  }

  &__logo {
    position: relative;
    display: block;
    justify-self: flex-start;
    width: width(381);
    //height: height(188);
    height: height(5);
    //margin-top: width(84);
    margin-top: width(5);
    margin-bottom: width(100);
    background: transparent;
    //background-image: url("./images/logo.png");
    //background-repeat: no-repeat;
    //background-position: 50% 0;
    //background-size: contain;
  }

  &__link {
    position: relative;
    display: block;
    width: width(477);
    height: height(109);
    margin-top: auto;
    margin-bottom: auto;
    text-decoration: none;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: transparent;

    &_ru {
      &::before {
        background-image: url("images/btn-normal-ru.svg");
      }

      &::after {
        background-image: url("images/btn-active-ru.svg");
      }
    }

    &_kz {
      &::before {
        background-image: url("images/btn-normal-kz.svg");
      }

      &::after {
        background-image: url("images/btn-active-kz.svg");
      }
    }

    &_ua {
      &::before {
        background-image: url("images/btn-normal-ua.svg");
      }

      &::after {
        background-image: url("images/btn-active-ua.svg");
      }
    }

    &_ge {
      &::before {
        background-image: url("images/btn-normal-ge.svg");
      }

      &::after {
        background-image: url("images/btn-active-ge.svg");
      }
    }

    &_az {
      &::before {
        background-image: url("images/btn-normal-az.svg");
      }

      &::after {
        background-image: url("images/btn-active-az.svg");
      }
    }

    &_uz {
      &::before {
        background-image: url("images/btn-normal-uz.svg");
      }

      &::after {
        background-image: url("images/btn-active-uz.svg");
      }
    }

    &_mn {
      &::before {
        background-image: url("images/btn-normal-mn.svg");
      }

      &::after {
        background-image: url("images/btn-active-mn.svg");
      }
    }

    &_kg {
      &::before {
        background-image: url("images/btn-normal-kg.svg");
      }

      &::after {
        background-image: url("images/btn-active-kg.svg");
      }
    }

    &_am {
      &::before {
        background-image: url("images/btn-normal-am.svg");
      }

      &::after {
        background-image: url("images/btn-active-am.svg");
      }
    }

    &_tr {
      &::before {
        background-image: url("images/btn-normal-tr.svg");
      }

      &::after {
        background-image: url("images/btn-active-tr.svg");
      }
    }

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      display: block;
      width: width(512, 477);
      height: height(145, 109);
      margin-left: width(math.div(512, -2), 477);
      margin-top: height(math.div(145, -2), 477);
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: contain;
      pointer-events: none;
      opacity: 1;
    }

    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      display: block;
      width: width(512, 477);
      height: height(145, 109);
      margin-left: width(math.div(512, -2), 477);
      margin-top: height(math.div(145, -2), 477);
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: contain;
      pointer-events: none;
      opacity: 0;
    }

    &:active {
      &::before {
        opacity: 0;
      }

      &::after {
        opacity: 1;
      }

      span {
        &::before {
          opacity: 0;
        }

        &::after {
          opacity: 1;
        }
      }
    }

    &:last-child {
      margin-bottom: width(100);
    }

    span {
      position: absolute;
      color: transparent;
    }
  }
}
